import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Phrase = styled.div`
  font-weight: 600;
  font-size: 2.5rem;
  color: #000;
  color: #333;
  margin: 0 auto;
  line-height: 1.5;
`;

const Text = styled.p`
  font-size: 1.5rem;
  color: #333;
  margin: 0 auto;
  line-height: 1.3;
`;

const Container = styled.div`
  margin: auto a {
    color: #2f80ed;
    border-bottom: 1px solid #d3d3d3;
    transition: all 300ms ease;
  }
  .phrase a:hover {
    opacity: 0.5;
  }

  h1, h2, h3 {
    font-size: 2rem;
    margin: 8px 0px;
  }
`;

const Landing = () => (
  <div>
    <Container className="max-w-screen-xl mx-auto p-5">
      <Phrase className="phrase">
        <span className="title">Terms of Service</span>
      </Phrase>{" "}
      <br />
      <Text>
        <b>
          BY CREATING AN ACCOUNT OR LOGGING INTO THIS WEB SITE, YOU SIGNIFY YOUR
          AGREEMENT TO ABIDE BY THESE TERMS OF SERVICE.
          <br />
          <br />
        </b>
        You agree that your assent, given electronically, will have the same
        legal effect as if you had personally signed this Agreement. To the
        extent permitted by law, this Agreement is intended to supersede any
        provisions of applicable law that might otherwise limit its
        enforceability or effect because they were entered into electronically.
        Please print a copy of this Agreement for future reference. If you
        create an Account, when you push the button to do so or to log into your
        Account, you are agreeing to the terms of this Agreement. If you do not
        create an Account, your use of the Website constitutes your agreement to
        the terms of this Agreement.
      </Text>
      <h3>Account Requirements</h3>
      <Text>
        Here are some rules for account creation and keeping it in good
        standing:
        <li>
          You must be a human to create an account. Accounts registered by
          "bots" or other automated methods are not permitted.
        </li>
        <li>
          To create an Account, you must be 13 years of age or older, or have
          permission from a parent or legal guardian. It is a violation of these
          Terms of Service to use the Website if you are under the age of
          thirteen (13) and do not have permission from your parent or guardian.
          If you are a child under 13, please show these Terms of Service to
          your parent or legal guardian, and do not create an Account without
          verifiable parental consent pursuant to the Children's Online Privacy
          Protection Act. If you as the Subscriber are a parent or legal
          guardian of a child under the age of 13, who may access or use the
          Website or the Materials, you hereby give your express consent for
          that child to use the Website and the Materials, which include all the
          features and functionality of both, including social media/community
          features (such as the Website’s forums and chat). You represent and
          warrant that you will not allow a child under the age of 13 to use the
          Services or the Website if you do not agree to the provisions of this
          Agreement.
        </li>
        <li>
          Your login may only be used by one person — i.e., a single login may
          not be shared by multiple people. If you are an organization, you must
          create a separate account for each user that wishes to access our
          Services; you cannot share an account with multiple people at your
          organization. If we determine that you are doing so, we may charge you
          fees for multiple Accounts, or may terminate your Account without
          refund.
        </li>
      </Text>
      <h3>User-generated content</h3>
      <Text>
        <li>
          Be nice. You agree that you will not threaten or verbally abuse other
          Members, use inapropriate language, or "spam" the platform.
        </li>
        <li>
          You acknowledge that any submissions you make to Enlight (i.e.,
          user-generated content including but not limited to: comments, forum
          posts, post submissions, text, as well as computer code and
          applications) (each, a “Submission”) may be edited, removed, modified,
          published, transmitted, and displayed by Enlight and you waive any
          rights you may have in having the material altered or changed in a
          manner not agreeable to you.{" "}
        </li>
        <li>
          You grant Enlight a perpetual, world-wide, royalty free,
          sub-licensable license to the Submissions (i.e. user-generated
          content), which includes without limitation the right for Enlight or
          any third party it designates, to use, copy, transmit, excerpt,
          publish, distribute, publicly display, create derivative works of,
          host, index, cache, tag, encode, modify and adapt (including without
          limitation the right to adapt to streaming, downloading, broadcast,
          mobile, digital, thumbnail, scanning or other technologies) in any
          form or media now known or hereinafter developed, any Submission
          posted by you on or to the Services or any other Web site owned by
          Enlight or its partners.
        </li>
        <li>
          You are solely responsible for the content of your Submissions.
          However, while Enlight does not and cannot review every Submission and
          is not responsible for the content of these messages, Enlight reserves
          the right to delete, move, or edit Submissions that it, in its sole
          discretion, deems abusive, defamatory, obscene, in violation of
          copyright or trademark laws, or otherwise unacceptable.
        </li>
      </Text>
      <h3>Account Termination</h3>
      <Text>
        You may close your account at any time -- just write to us at
        team@enlight.nyc. We reserve the right to suspend or terminate your
        account at any time, with or without cause, with or without notice. We
        also reserve the right to refuse service to anyone for any legally
        permissable reason at any time.
      </Text>
      <h3>User Account Security</h3>
      <Text>
        You are responsible to keep your account secure. If you are aware of any
        unauthorized or suspicious activity, please email us at team@enlight.nyc
      </Text>
      <h3> Content distribution</h3>
      <Text>
        We recognize that content can be shared electronically and we encourage
        ideas to be shared as part of the learning process. However, selling
        and/or distributing content from Enlight is not permitted. Please email
        us if you would like to partner or use our content. (team@enlight.nyc)
      </Text>
      <h3>Disclaimer</h3>
      <Text>
        The information provided by us on this website is on an ‘as is’, ‘as
        available’ basis and is for general, indicative purposes only. Although
        every effort is made to ensure the reliability of the information
        contained in this website, this shall not constitute as a warranty,
        either expressed or implied with regards to the accuracy and adequacy of
        any information stated herein.
      </Text>
    </Container>
  </div>
);

export default Landing;
